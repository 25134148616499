import React, {useEffect} from "react";
import '../../../assets/css/styles.css';
import labels from '../../../language/srb';
import Container from 'react-bootstrap/Container';
import Header from "../../components/header";
import Footer from "../../components/footer";
import {scrollToTop} from "../../../utilities/util";

function AboutUsPage() {

    useEffect(() => {
        scrollToTop();
    }, [])

    return (
        <>
            <Header/>
            <Container fluid className="pt-5 d-flex justify-content-center" style={{backgroundColor: "#F4F4F4", height:"100%"}}>
                <div className='o-nama-container p-5' style={{marginTop: '80px', maxWidth: "1250px", marginBottom:"80px"}}>
                    <p className="h3 ff-arimo mb-5">O TAMARI</p>
                    <p>Tamara je jedna, pre svega, ambiciozna devojka, koja je putujući po svetu, kući uvek donosila strane marke veša i grudnjaka. Zaljubljenik je u iste i ima svoje čitave kolekcije! </p>
                    <p>
                    S tim u vezi, Tamara je poželela da i nama čitavu Evropu dovede u Srbiju, i da nam omogući udobnost veša u kojoj ona uživa već godinama.
                    </p>
                    <p>
                    Tako je Tamara pokrenula domaći brend pod svojim imenom, bavi se internet prodajom, ima tri radnje u Beogradu, a kako se šuška, Tamara će se useliti i u druge gradove Srbije! 
O kvalitetu ne morate brinuti, Tamara je po tom pitanju vrlo zahtevna, da ne kažemo malo i razmažena, pa ukoliko je odabrala za sebe, znajte da je sjajno!
                    </p>
                    <p>
                    Lormar, Sielei, Selene (dodaj koje jos) i mnoge druge marke, deo su našeg asortimana. 
Znate šta još? Tamara kaže da svaka dama zaslužuje bar neki od ovih komada, jer svaki ženski ormar voli TAMARU!
                    </p>
                    <p className="mt-5" style={{float: 'right'}}>Vаša Tamara Italian Lingerie!</p>
                </div>
            </Container>
            <Footer/>
        </>
    );
}

export default AboutUsPage;
