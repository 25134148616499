import React, { useEffect, useState } from "react";
import '../../../assets/css/styles.css';
import './css/index.css';
import Header from "../../components/header";
import Footer from "../../components/footer";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Boje from "../../components/returnBoje";
import Velicine from "../../components/returnVelicine";
import axios from "axios";
import Config from "../../../config/config";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { IoArrowBackCircleSharp } from 'react-icons/io5';
import history from "../../../utilities/history";
import { addToCart, currencyFormat, removeUnderline, scrollToTop } from "../../../utilities/util";
import DubinaKorpe from "../../components/returnDubinuKorpe";
import Button from 'react-bootstrap/Button';
import { FaShoppingCart } from 'react-icons/fa';
import CenterMode from "../../components/itemCarousel";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useMediaQuery } from 'react-responsive';

function isKorpaOrCarapa(niz) {
    if (niz.includes('B'))
        return 'Dubina Korpe :';
    if (niz.includes('C'))
        return 'Dubina Korpe :';
    if (niz.includes('D'))
        return 'Dubina Korpe :';
    if (niz.includes('E'))
        return 'Dubina Korpe :';
    if (niz.includes('F'))
        return 'Dubina Korpe :';
    return 'Denaža :';
}

function ProductOverviewPage() {
    const { id } = useParams();
    const [proizvod, setProizvod] = useState(null);
    const [tip, setTip] = useState("");
    const [podtipValue, setpodTipValue] = useState("Dubina korpe :");
    const [kolicina, setKolicina] = useState(1);
    const [boja, setBoja] = useState(null);
    const [velicina, setVelicina] = useState(null);
    const [dubinaKorpe, setDubinaKorpe] = useState(null);
    const [defaultSlika, setDefaultSlika] = useState(proizvod?.defaultSlika);
    const [showPopup, setShowPopup] = useState(false);
    const [isDiscountActive, setIsDiscountActive] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    useEffect(() => {
        scrollToTop();
        const editItem = JSON.parse(localStorage.getItem("editItem"));
        if (editItem) {
            setVelicina(editItem.velicina);
            setKolicina(editItem.kolicina);
            setBoja(editItem.boja);
            if (editItem.dubinaKorpe) {
                setDubinaKorpe(editItem.dubinaKorpe);
            }
            setTimeout(() => {
                localStorage.setItem('editItem', null);
            }, 1000)
        }
    }, [])


    useEffect(() => {
        axios.get(`${Config.api.baseUrl}v1/proizvod/${id}`)
            .then(res => {
                setProizvod(res.data.data);
                setpodTipValue(isKorpaOrCarapa(res.data.data.podtip))
                setDefaultSlika(res.data.data.defaultSlika);
                setTip(res.data.data.tip === undefined ? "" : res.data.data.tip);
                const editItem = JSON.parse(localStorage.getItem("editItem"));
                if (!editItem) {
                    setBoja(res.data.data?.boje[0]);
                    setVelicina(res.data.data.velicine[0]);
                }
            })

    }, [id]);


    function calculatePrice() {
        let price = proizvod.cena;
        if (proizvod.discounts?.length > 0 || proizvod.discountOne?.length > 0) {
            proizvod.discounts?.forEach(discount => {
                price -= proizvod.cena * discount.procenat;
            })
            proizvod.discountOne?.forEach(discount => {
                price -= proizvod.cena * discount.procenat;
            })
            return Math.round(price);
        }
    }

    function handleChangeKolicina(event) {
        setKolicina(event.target.value);
    }

    function getPhotosByColor(color) {
        const filtered = proizvod?.slike.filter((slika) => slika.includes(color));
        if (filtered && filtered.length > 0) {
            return filtered;
        }
        return proizvod?.slike;
    }

    return (
        <>
            {
                showPopup &&
                <>
                    <div className='background-popup' />
                    <div className='popup-delete'>
                        <div className="p-4">
                            <div className="text-center ">
                                <BsFillCheckCircleFill style={{ height: "50px", width: "50px", color: "#04BB00" }} />
                                <p className='h5 mt-3'>Proizvod je dodat u korpu!</p>
                                <hr />
                            </div>
                            <p>{proizvod.brend.toUpperCase()} | {proizvod.moda ? 'Moda' : 'Klasika'}</p>
                            <p className="h4 mb-3">{`${proizvod.naziv.toUpperCase()}`}</p>
                            <div className="d-flex">
                                <p className="pe-5 font-size-16">Količina: {kolicina}</p>
                                <p className="font-size-16">Veličina: {velicina}</p>
                            </div>
                            <div className="d-flex">
                                <p className="pe-5 font-size-16">Boja: {boja}</p>
                                {
                                    dubinaKorpe &&
                                    <p className="font-size-16">Dubina korpe: {dubinaKorpe}</p>
                                }
                            </div>
                            {/* cena
                            <p className='mt-3 mb-0 text-22 me-5 pe-5' style={{ fontWeight: '600' }}>Cena: {kolicina} x &nbsp;
                                {calculatePrice() ? <>
                                    <del>{currencyFormat(proizvod.cena)} RSD</del>&nbsp;
                                    {currencyFormat(calculatePrice())}</> :
                                    <>{currencyFormat(proizvod.cena)} RSD</>} <span
                                        className={'text-15'}></span></p> */}

                        </div>
                        <div style={{ position: 'absolute', bottom: '0', width: '100%', padding: '15px' }}>
                            <div className="d-flex justify-content-between mt-3 pb-3">
                                <Button style={{ borderRadius: 0, backgroundColor: '#D9D9D9', color: 'black', border: 'none', fontWeight: '600', height: '50px' }}
                                    onClick={() => { history.push('/cart-page') }}>
                                    POGLEDAJ KORPU
                                </Button>
                                <Button style={{ borderRadius: 0, backgroundColor: '#D9D9D9', color: 'black', border: 'none', fontWeight: '600', height: '50px' }}
                                    onClick={() => setShowPopup(false)}>NASTAVI KUPOVINU</Button>
                            </div>
                        </div>
                    </div>

                </>
            }
            {
                !proizvod ? <Loader /> :
                    <>
                        <Header />
                        <Container fluid className='product-overview pt-5 pt-md-0'>
                            <div className='cursor-pointer d-none d-md-block pt-5 ps-5' onClick={() => history.goBack()}>
                                <IoArrowBackCircleSharp className='back-button' /><p
                                    className='d-inline-block nazad'>Nazad</p>
                            </div>
                            <Container >
                                <div className={`d-flex justify-content-between mt-3 ${isMobile && 'flex-column pt-3'}`}>
                                    <div className={`${isMobile && 'justify-content-center'}`}>
                                        <img
                                            className="product-overview-img"
                                            src={process.env.PUBLIC_URL + `/Imgs/${defaultSlika}`}
                                            alt="slider-photo"
                                        />
                                    </div>
                                    <div className='d-none d-md-block p-0 text-center ps-2 pe-4' style={{ maxHeight: '533px' }}>
                                        {
                                            proizvod &&
                                            getPhotosByColor(boja).map((slika) => {
                                                return (
                                                    <img
                                                        className={slika === defaultSlika ? 'active img-item mb-3 mx-auto p-0' : 'img-item mb-3 mx-auto p-0'}
                                                        src={process.env.PUBLIC_URL + `/Imgs/${removeUnderline(slika)}`}
                                                        alt={slika}
                                                        onMouseEnter={() => setDefaultSlika(slika)}
                                                        onClick={() => {
                                                            window.scrollTo({
                                                                top: 0,
                                                                behavior: "smooth"
                                                            });
                                                        }}
                                                    />
                                                );
                                            })
                                        }
                                    </div>
                                    <div className='d-block d-md-none p-0 text-center px-2 mt-3'>
                                        <Row>
                                            {
                                                proizvod &&
                                                getPhotosByColor(boja).map((slika) => {
                                                    return (
                                                        <Col xs='6'>
                                                            <img
                                                                className={slika === defaultSlika ? 'active img-item mb-3 mx-auto p-0' : 'img-item mb-3 mx-auto p-0'}
                                                                src={process.env.PUBLIC_URL + `/Imgs/${slika}`}
                                                                alt={slika}
                                                                onClick={() => {
                                                                    setDefaultSlika(slika);
                                                                    window.scrollTo({
                                                                        top: 0,
                                                                        behavior: "smooth"
                                                                    });
                                                                }}
                                                            /></Col>
                                                    );
                                                })
                                            }
                                        </Row>
                                    </div>
                                    <div className="ps-5 pb-5">
                                        <p>{proizvod.brend.toUpperCase()} | {proizvod.moda ? 'Moda' : 'Klasika'}</p>
                                        <p className="h4 fw-bold">{`${proizvod.kategorija.toUpperCase()} ${tip.toUpperCase()} ${proizvod.naziv.toUpperCase()}`}</p>
                                        {/* cena
                                        <p className='mt-3 mb-0 text-22 me-5 pe-5' style={{ fontSize: '40px', fontWeight: '600' }}>
                                            {calculatePrice() ? <>
                                                <del>{currencyFormat(proizvod.cena)} RSD</del>&nbsp;
                                                {currencyFormat(calculatePrice())}</> :
                                                <>{currencyFormat(proizvod.cena)} RSD</>} <span
                                                    className={'text-15'}></span></p> */}
                                        {
                                            proizvod.podtip[0] &&
                                            <Col md='12'>
                                                <p className='mt-3 mb-2 d-inline-block'>{podtipValue}</p>
                                            </Col>
                                        }
                                        {
                                            proizvod.podtip[0] && proizvod.podtip.length >= 1 &&
                                            <Col md='12'>
                                                <DubinaKorpe nameOfClass='size-item cursor-pointer'
                                                    dubinaKorpe={dubinaKorpe} setDubinaKorpe={setDubinaKorpe}
                                                    product={proizvod} />
                                            </Col>
                                        }
                                        <p className='mt-3 mb-2'>Boja :</p>
                                        <Boje
                                            nameOfClass='color-item cursor-pointer'
                                            boja={boja}
                                            setBoja={setBoja}
                                            product={proizvod}
                                            showName={true}
                                            floatEnd={false}
                                            getPhotosByColor={getPhotosByColor}
                                            setDefaultSlika={setDefaultSlika} />
                                        <p className='mt-3 mb-2'>Veličina :</p>
                                        <Velicine nameOfClass='size-item cursor-pointer' product={proizvod}
                                            velicina={velicina} setVelicina={setVelicina}
                                            floatEnd={false} isProductOverview={true} />

                                        <p className="mt-2 mb-0">Količina:</p>
                                        <input className="me-2 mt-1 kolicina-input" defaultValue={kolicina}
                                            onChange={handleChangeKolicina} />
                                        <div className={`buy-btn text-center py-2 mt-4 justify-content-center mx-auto  ${isMobile && 'ms-0 mb-3'}`}
                                            onClick={() => {
                                                // proizvod.cenaSaPopustom = calculatePrice() || null;
                                                // addToCart({
                                                //     proizvod: proizvod,
                                                //     kolicina: kolicina,
                                                //     dubinaKorpe: dubinaKorpe,
                                                //     velicina: velicina,
                                                //     boja: boja
                                                // });
                                                // setShowPopup(true);
                                                // scrollToTop();
                                            }}><div className={`d-flex justify-content-center p-2`} style={{ backgroundColor: "black", color: 'white', width: '250px' }}>
                                                <FaShoppingCart className="mt-1" style={{ width: '25px', height: '25px' }} />
                                                <p className="mb-0 mt-1 ps-3">
                                                    DODAJ U KORPU
                                                </p>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="container mt-2 mb-5">
                                    {
                                        proizvod.opis !== ' ' &&
                                        <div className={`${isMobile && 'pb-3'}`}>
                                            <p className='mt-3 mb-0 text-justify'>Opis : </p>
                                            <hr />
                                            {
                                                proizvod.opis.split('\n').map((item) => {
                                                    return (
                                                        <p className='mb-0'>{item}</p>
                                                    );
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                                {
                                    !isMobile &&
                                    <>
                                        {/* <p className='mt-5' style={{ color: 'grey', fontSize: '25px' }}>Preporučeno:</p>
                                        <div className="mt-5 pb-5">
                                            <CenterMode />
                                        </div> */}
                                    </>
                                }

                            </Container>
                        </Container>
                        <Footer />
                    </>
            }

        </>
    );
}

export default ProductOverviewPage;
