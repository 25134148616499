const labels = {
    home: "početna",
    man: "Muškarci",
    woman: "Žene",
    tamara: "Tamara",
    aboutUs: "o nama",
    contact: "kontakt",
    errorPageTitle: "Trenutno nismo u mogućnosti da obradimo vaš zahtev",
    maintenanceMessage: "Vraćamo se uskoro!",
    pageNotFoundMessage: "Ups! Stranica ne postoji!",
};

export default labels;