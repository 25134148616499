import Footer from './footer';
import Header from './header';
import Slider from './slider/slider';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../assets/css/styles.css';
import CenterMode from "./itemCarousel";
import history from "../../utilities/history";
import { useEffect } from "react";
import { scrollToTop } from "../../utilities/util";
import store from "../../app/store";
import { setPolSearchParams } from "../../app/store/searchParams/actions";
import { useMediaQuery } from 'react-responsive';

export default function Root() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    useEffect(() => {
        scrollToTop();
    }, [])

    return (
        <>
            <Header />
            <Slider />
            <div style={{ background: 'black' }} >
            <Container className='mb-5 p-0 mid-container' style={{ marginBottom: '100px' }} fluid>
                <div className={`d-flex justify-content-center ${isMobile && 'flex-column'}`} style={{marginTop: '100px'}}>
                    <div className='gender-box cursor-pointer pb-3 p-md-3 mx-auto' onClick={() => {
                        history.push('/product-list');
                        store.dispatch(setPolSearchParams('zenski'));
                    }}>
                    <p style={{position: 'absolute', left: '30px', bottom: '100px', fontSize: '66px', color: 'white'}} className='ff-syncopate-bold mb-0'>ZA</p>
                    <p style={{position: 'absolute', left: '30px', bottom: '0', fontSize: '66px', color: 'white'}} className='ff-syncopate-bold mb-0'>NJU</p>
                        <img
                            style={{opacity: '50%'}}
                            className='box-img'
                            src={process.env.PUBLIC_URL + `/Imgs/zene-box.png`}>
                        </img>
                    </div>
                    <div className={`gender-box cursor-pointer pb-3 p-md-3 mx-auto`} onClick={() => {
                        history.push('/product-list');
                        store.dispatch(setPolSearchParams('muski'));
                    }}>
                    <p style={{position: 'absolute', left: '30px', bottom: '100px', fontSize: '66px', color: 'white'}} className='ff-syncopate-bold mb-0'>ZA</p>
                    <p style={{position: 'absolute', left: '30px', bottom: '0', fontSize: '66px', color: 'white'}} className='ff-syncopate-bold mb-0'>NJEGA</p>
                        <img
                            style={{opacity: '50%'}}
                            className='box-img'
                            src={process.env.PUBLIC_URL + `/Imgs/muskarci-boxx.png`}>
                        </img>
                    </div>
                </div>
            </Container>
            <p className='ff-syncopate-bold fw-bold text-center d-none d-md-block' style={{color: 'white', fontSize: '50px'}}>TOP MODELI</p>
            <Container className='d-none d-md-block justify-content-center d-flex' style={{width: '1000px', paddingBottom: '120px'}}>
                <CenterMode />
            </Container>
            </div>  
            <Footer />
        </>
    );
}