import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import productsCarouselData from "../../data/productsCarouselData";
import ProductCard from "../pages/product-info";
import "../../assets/css/styles.css";
import axios from "axios";
import Config from "../../config/config";
import {firstLetter, removeUnderline, scrollToTop} from "../../utilities/util";
import history from "../../utilities/history";
import { Carousel } from '@trendyol-js/react-carousel';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

export default class CenterMode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popularProducts: null,
            p1: null,
            p2: null,
            p3: null,
            p4: null,
            p5: null,
        }
    }

    componentDidMount() {
        axios.get(`${Config.api.baseUrl}v1/popular-products`)
            .then(res => {
                this.setState({ popularProducts: res.data.data });
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.popularProducts !== prevState.popularProducts) {
            axios.get(`${Config.api.baseUrl}v1/proizvod/${this.state.popularProducts.p1}`)
                .then(res => {
                    console.log('res', res);
                    this.setState({ p1: res.data.data });
                })
            axios.get(`${Config.api.baseUrl}v1/proizvod/${this.state.popularProducts.p2}`)
                .then(res => {
                    this.setState({ p2: res.data.data });
                })
            axios.get(`${Config.api.baseUrl}v1/proizvod/${this.state.popularProducts.p3}`)
                .then(res => {
                    this.setState({ p3: res.data.data });
                })
            axios.get(`${Config.api.baseUrl}v1/proizvod/${this.state.popularProducts.p4}`)
                .then(res => {
                    this.setState({ p4: res.data.data });
                })
            axios.get(`${Config.api.baseUrl}v1/proizvod/${this.state.popularProducts.p5}`)
                .then(res => {
                    this.setState({ p5: res.data.data });
                })

        }
    }

    render() {
        // const settings = {
        //     className: "center item-carousel",
        //     centerMode: true,
        //     infinite: true,
        //     centerPadding: "60px",
        //     slidesToShow: 4,
        //     speed: 500,
        // };
        return (
            <div className="my-5">
                <Carousel show={2.5} slide={2} swiping={true} infinite={true} leftArrow={<IoIosArrowBack style={{height: '400px', width: '50px', color: 'white'}}/>} rightArrow={<IoIosArrowForward  style={{height: '400px', width: '50px', color: 'white'}} />}>
                        {
                            this.state.p1 || productsCarouselData[0] &&
                            <div>
                                <img className={"carousel-img"} src={process.env.PUBLIC_URL + `/Imgs/${removeUnderline(this.state.p1?.defaultSlika || productsCarouselData[0].defaultSlika)}`}
                                    alt={''}
                                    onClick={() => history.push(`/product/${this.state.p1?.id || productsCarouselData[0].id}`)}
                                />
                            </div>
                        }
                        {
                            this.state.p2 || productsCarouselData[1] &&
                            <div>
                                <img className={"carousel-img"} src={process.env.PUBLIC_URL + `/Imgs/${removeUnderline(this.state.p2?.defaultSlika || productsCarouselData[1].defaultSlika)}`}
                                    alt={''}
                                    onClick={() => history.push(`/product/${this.state.p2?.id || productsCarouselData[1].id}`)}
                                />
                            </div>
                        }
                        {
                            this.state.p3 || productsCarouselData[2] &&
                            <div>
                                <img className={"carousel-img"} src={process.env.PUBLIC_URL + `/Imgs/${removeUnderline(this.state.p3?.defaultSlika || productsCarouselData[2].defaultSlika)}`}
                                    alt={''}
                                    onClick={() => history.push(`/product/${this.state.p3?.id || productsCarouselData[2].id}`)}
                                />
                            </div>
                        }
                        {
                            this.state.p4 || productsCarouselData[3] &&
                            <div>
                                <img className={"carousel-img"} src={process.env.PUBLIC_URL + `/Imgs/${removeUnderline(this.state.p4?.defaultSlika || productsCarouselData[3].defaultSlika)}`}
                                    alt={''}
                                    onClick={() => history.push(`/product/${this.state.p4?.id || productsCarouselData[3].id}`)}
                                />
                            </div>
                        }
                        {
                            this.state.p5 || productsCarouselData[4] &&
                            <div>
                                <img className={"carousel-img"} src={process.env.PUBLIC_URL + `/Imgs/${removeUnderline(this.state.p5?.defaultSlika || productsCarouselData[4].defaultSlika)}`}
                                    alt={''}
                                    onClick={() => history.push(`/product/${this.state.p5?.id || productsCarouselData[4].id}`)}
                                />
                            </div>
                        }
	            </Carousel>
                {/* {
                    <Slider {...settings}>
                       
                    </Slider>
                } */}
            </div>
        );
    }
}